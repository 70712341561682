<template>
    <div>
		<el-form ref="queryForm" class="query-box" v-model="queryParams" :inline="true">
			<el-form-item :label="translate('AUTHORITY.NAME')" prop="name" label-width="120px">
				<el-input v-model="queryParams.name"
						  :placeholder="translate('FROM.PLACEHOLDER', {param: 'AUTHORITY.NAME'})" clearable
						  style="width: 240px"
						  size="small" @keyup.enter.native="handleQuery"/>
			</el-form-item>
			<el-form-item :label="translate('ROLE.ROLE_CODE')" prop="roleCode" label-width="120px">
				<el-input v-model="queryParams.roleCode" :placeholder="translate('FROM.PLACEHOLDER', {param: 'ROLE.ROLE_CODE'})"
						  clearable style="width: 240px" size="small"/>
			</el-form-item>
			<el-form-item :label="translate('COMMON.STATUS')" prop="status" label-width="120px">
				<el-select v-model="queryParams.status"
						   :placeholder="translate('COMMON.STATUS')" clearable size="small" style="width: 240px">
					<el-option v-for="(value,key) in dicts.STATUS" :key="key" :label="value" :value="value"/>
				</el-select>
			</el-form-item>
			<el-form-item :label="translate('COMMON.CREATE_TIME')" label-width="120px">
				<el-date-picker v-model="createDateRange"
								size="small" value-format="yyyy-MM-dd"
								type="daterange" range-separator="-"
								style="width: 240px"
								:start-placeholder="translate('COMMON.START_DATE')"
								:end-placeholder="translate('COMMON.END_DATE')"/>
			</el-form-item>

			<el-form-item :label="translate('COMMON.UPDATE_TIME')" label-width="120px">
				<el-date-picker v-model="updateDateRange"
								size="small" value-format="yyyy-MM-dd"
								type="daterange" range-separator="-"
								style="width: 240px"
								:start-placeholder="translate('COMMON.START_DATE')"
								:end-placeholder="translate('COMMON.END_DATE')"/>
			</el-form-item>
		</el-form>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">{{translate('OPERATION.QUERY')}}</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="handleResetQuery">{{translate('OPERATION.RESET')}}</el-button>
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd" v-permission="'SYS_ROLE_INSERT'">{{translate('OPERATION.ADD')}}</el-button>
				<el-button type="warning" icon="el-icon-download" size="mini" @click="handleExport" v-permission="'SYS_ROLE_EXPORT'">{{translate('OPERATION.EXPORT')}}</el-button>
			</el-col>
		</el-row>

		<CustomForm :dicts="dicts" :on-fresh="showCustomForm" @on-close="handlerCustomFormOnclose" :isAdd="true"></CustomForm>
	</div>
</template>

<script>
	import CustomForm from "./CustomForm";
	import {UserApi} from '@/api';
    export default {
        name: "ToolBar",
		components: {CustomForm},
		props: {
			dicts: {
				type: Object,
				default: {}
			}
		},
		data() {
			return {
				queryParams: {},
				createDateRange: [],
				updateDateRange: [],
				showCustomForm: false,
			}
		},
		mounted() {
		},
		methods: {
			handlerCustomFormOnclose() {
				this.showCustomForm = false;
				this.commitChange();
			},
			handleQuery() {
				this.commitChange();
			},
			handleResetQuery(){
				this.queryParams = {};
				this.createDateRange = [];
				this.updateDateRange = [];
				this.commitChange();
			},
			handleAdd(){
				this.showCustomForm = true;
			},
			handleExport() {
				this.$confirm(this.translate('FROM.CONFIRM_EXPORT', {module: 'MODULE.ROLE'}), this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					this.dealWithQueryParams();
					let result = (await UserApi.role.export(this.queryParams));
					if (result.success) {
						this.downloadFile(result.data);
					}
				});
			},
			dealWithQueryParams() {
				let [startCreateTime, endCreateTime] = this.createDateRange;
				this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
				this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');

				let [startUpdateTime, endUpdateTime] = this.updateDateRange;
				this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
				this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
			},
			commitChange() {
				this.dealWithQueryParams();
				let params = {...this.queryParams};
				this.$emit('on-change', params);
			},
		}
    }
</script>

<style scoped>

</style>
